import { checkIsBusinessUser } from "selectors/user";
import {
  getCurrentQuickBooksRealmId,
  getCurrentXeroOrganizationId,
  getCurrentZohoOrganizationId,
  getSelectedBusinessData,
  getSelectedBusinessLocations
} from "selectors/businesses";
import { useSelector } from "react-redux";
import AddressField from "./lib/AddressField";
import AmountBaseField from "./lib/AmountBaseField";
import AmountField from "./lib/AmountField";
import AmountVatField from "./lib/AmountVatField";
import CurrencyField from "./lib/CurrencyField";
import DataConstants from "const/DataConstants";
import DetailedTaxRateField from "./lib/DetailedTaxRateField";
import DocumentIdField from "./lib/DocumentIdField";
import DocumentTypeField from "./lib/DocumentTypeField";
import DueDateField from "./lib/DueDateField";
import ExportAsField from "./lib/ExportAsField";
import IssueDateField from "./lib/IssueDateField";
import LocationField from "./lib/LocationField";
import MatchingTransactionsField from "./lib/MatchingTransactionsField";
import MemoField from "./lib/MemoField";
import PayToField from "./lib/PayToField";
import PaymentDateField from "./lib/PaymentDateField";
import React from "react";
import useDocumentContext from "hooks/useDocumentContext";

const {
  DOCUMENT_TYPES: { RECEIPT },
  ADVANCED_TRANSACTION_TYPES: { EXPENSE }
} = DataConstants;

const Fields = () => {
  const { documentState, documentData: { paidTransactions = [] } } = useDocumentContext();

  const { type, exportAs, detailedTaxRates } = documentState;

  const locations = useSelector(getSelectedBusinessLocations);

  const businessUser = useSelector(checkIsBusinessUser);

  const { settings: { advancedDocumentsWorkflow } = {} } = useSelector(getSelectedBusinessData);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const zohoBusiness = !!useSelector(getCurrentZohoOrganizationId);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const receiptType = type === RECEIPT;

  return (
    <>
      <DocumentTypeField />
      {!businessUser && receiptType && quickBooksBusiness && <ExportAsField />}
      <DocumentIdField />
      <CurrencyField />
      <AmountBaseField />
      {detailedTaxRates.length
        ? detailedTaxRates.map(({ id, name }) => (
          <DetailedTaxRateField key={id} id={id} name={name} />
        ))
        : <AmountVatField />}
      <AmountField />
      <IssueDateField />
      <DueDateField />
      {!businessUser && (!!(((xeroBusiness || zohoBusiness) && !paidTransactions.length)
        || (quickBooksBusiness && receiptType && exportAs === EXPENSE))) && (
        <>
          <PayToField />
          {(xeroBusiness || zohoBusiness) && <PaymentDateField />}
        </>
      )}
      <AddressField />
      {!!locations.length && <LocationField />}
      {!!quickBooksBusiness && <MemoField />}
      {!!advancedDocumentsWorkflow && <MatchingTransactionsField />}
    </>
  );
};

export default React.memo(Fields);
