import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { getUploadingDocumentsCount } from "selectors/documents";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Features from "const/Features";
import ImportDocumentsWindow from "nlib/windows/ImportDocumentsWindow";
import React, { useCallback, useState } from "react";
import useUploadDocuments from "hooks/useUploadDocuments";

const HeaderButtons = ({ disabled, refetchDocuments }) => {
  const { uiTexts } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const documentsCurrentUploadCount = useSelector(getUploadingDocumentsCount);

  const [importDocumentsWindowOpened, setImportDocumentsWindowOpened] = useState(false);

  const uploadDocuments = useUploadDocuments();

  const handleImportDocumentsButtonClick = useCallback(() => {
    setImportDocumentsWindowOpened(true);
  }, []);

  const handleImportDocumentsWindowClose = useCallback(async(result) => {
    setImportDocumentsWindowOpened(false);

    if (!result) return;

    const response = await uploadDocuments(result);

    if (response) refetchDocuments();
  }, [uploadDocuments, refetchDocuments]);

  return (
    <>
      <div className={Css.headerButtons}>
        {Features.checkIsAllowed(activeOrganization.pricingPlan, Features.DOCUMENTS_IMPORT) && (
          <Button
            large primary
            data-loading={documentsCurrentUploadCount || undefined}
            disabled={disabled || !!documentsCurrentUploadCount}
            icon={documentsCurrentUploadCount ? Icons.Spinner : Icons.CloudArrowUp}
            onClick={handleImportDocumentsButtonClick}>
            {uiTexts.importDocuments}
          </Button>
        )}
      </div>
      {importDocumentsWindowOpened && (
        <ImportDocumentsWindow onClose={handleImportDocumentsWindowClose} />
      )}
    </>
  );
};

export default React.memo(HeaderButtons);
