import Css from "./style.module.scss";

import { checkMatchesFetching } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import DocumentsActions from "actions/DocumentsActions";
import DocumentsStatusFilter from "./lib/DocumentsStatusFilter";
import DocumentsTable from "./lib/DocumentsTable";
import DuplicatedDocumentsWindow from "./lib/DuplicatedDocumentsWindow";
import EditDocument from "./lib/EditDocument";
import HeaderButtons from "./lib/HeaderButtons";
import Page from "nlib/common/Page";
import PageContent from "nlib/common/PageContent";
import PageHeader from "nlib/common/PageHeader";
import React, { useCallback, useEffect, useRef } from "react";
import TasksBlock from "nlib/common/TasksBlock";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import useAvailableWidth from "hooks/useAvailableWidth";
import useDocumentUtils from "hooks/useDocumentUtils";
import useDocuments from "hooks/useDocuments";
import useEnvVars from "hooks/useEnvVars";
import useShowCommonModal from "hooks/useShowCommonModal";
import useShowModal from "hooks/useShowModal";

const SIMPLIFIED_LAYOUT_MAX_WIDTH = 1020;

const DocumentsPage = () => {
  const dispatch = useDispatch();

  const showCommonModal = useShowCommonModal();

  const contentRef = useRef();

  const [envVars, setEnvVars] = useEnvVars();

  const [editDocumentId] = envVars.editItem ? envVars.editItem.split(".") : [];

  const matchesFetching = useSelector(checkMatchesFetching);

  const { messages } = useSelector(getTextsData);

  const { openMatchingTransactionsWindow } = useDocumentUtils();

  const {
    fetching: documentsFetching,
    data: documentsData,
    refetch: refetchDocuments
  } = useDocuments();

  const [
    duplicatedDocumentsModalData,
    showDuplicatedDocumentModal,
    onCloseManageDuplicationsModal
  ] = useShowModal();

  const { containerRef, availableWidth } = useAvailableWidth([documentsFetching, envVars.editTask, envVars.editItem]);

  const simplifyLayout = availableWidth <= SIMPLIFIED_LAYOUT_MAX_WIDTH;

  const fetchingData = documentsFetching || matchesFetching;

  const openDocument = useCallback(async(id) => {
    setEnvVars({ editTask: null });

    const [documentId] = (id || "").split(".");

    const documentData = Utils.arrayFindById(documentsData, documentId);

    if (!documentData || documentData.status === DataConstants.STATUSES.TO_EXTRACT) return;

    if (documentData.duplicatedDocumentsIds && documentData.duplicatedDocumentsIds.length) {
      const modalResult = await showDuplicatedDocumentModal(documentData);

      if (modalResult) {
        const { deleteDocument, duplicatedDocumentsIds } = modalResult;

        if (deleteDocument) {
          const deleteModalResult = await showCommonModal({ text: messages.documentDeleteConfirm, confirm: true });

          if (deleteModalResult) {
            await dispatch(DocumentsActions.deleteDocument(id));
            refetchDocuments();
          }
        }
        if (duplicatedDocumentsIds) {
          await dispatch(DocumentsActions.editDocument(documentId, { duplicatedDocumentsIds: [] }, false, true));
          setEnvVars({ editItem: id });
          refetchDocuments();
        }
      }

      return;
    }

    setEnvVars({ editItem: id });
  }, [documentsData, messages, dispatch, refetchDocuments, setEnvVars, showCommonModal, showDuplicatedDocumentModal]);

  const handlePairTransactionsLinkClick = useCallback(async(id) => {
    const documentData = Utils.arrayFindById(documentsData, id);

    if (documentData) {
      const result = await openMatchingTransactionsWindow(documentData);

      if (result) refetchDocuments();
    }
  }, [documentsData, openMatchingTransactionsWindow, refetchDocuments]);

  const handleEditDocumentWindowClose = useCallback((refetchData) => {
    setEnvVars({ editItem: null });

    if (refetchData) refetchDocuments(true);
  }, [refetchDocuments, setEnvVars]);

  const handleStatusTabClick = useCallback((value) => {
    if (envVars.status === value) refetchDocuments();
  }, [envVars.status, refetchDocuments]);

  const filtersEnabled = [
    envVars.status, envVars.text, envVars.fromDate, envVars.toDate, envVars.accountId, envVars.type
  ].find(Boolean);

  useEffect(() => {
    dispatch(UiActions.setOpenedCommentsEntityId(null));
  }, [dispatch, envVars.page, envVars.status, envVars.pageSize]);

  return (
    <>
      <Page className={Css.documentsPage} pageRef={containerRef}>
        <PageContent ref={contentRef}>
          <TasksBlock />
          <PageHeader>
            {(!!documentsData.length || filtersEnabled) && (
              <HeaderButtons
                disabled={fetchingData}
                documentsData={documentsData}
                refetchDocuments={refetchDocuments} />
            )}
          </PageHeader>
          <DocumentsStatusFilter
            disabled={fetchingData}
            simplifyLayout={simplifyLayout}
            onClick={handleStatusTabClick} />
          <DocumentsTable
            fetchingData={fetchingData}
            simplifyLayout={simplifyLayout}
            documentsData={documentsData}
            openDocument={openDocument}
            refetchDocuments={refetchDocuments}
            onPairTransactionsLinkClick={handlePairTransactionsLinkClick} />
        </PageContent>
      </Page>
      {!!editDocumentId && (
        <EditDocument
          key={editDocumentId}
          documentId={editDocumentId}
          openDocument={openDocument}
          refetchDocuments={refetchDocuments}
          onClose={handleEditDocumentWindowClose} />
      )}
      {duplicatedDocumentsModalData && (
        <DuplicatedDocumentsWindow
          documentData={duplicatedDocumentsModalData}
          onClose={onCloseManageDuplicationsModal} />
      )}
    </>
  );
};

export default React.memo(DocumentsPage);
