import Css from "./style.module.scss";

import { Checkbox, DropDown } from "nlib/ui";
import { DropDownCaret, DropDownContent, DropDownMenuItem, DropDownToggle } from "nlib/ui/DropDown";
import { checkIsBusinessUser } from "selectors/user";
import { getActiveOrganization } from "selectors/organizations";
import {
  getCurrentQuickBooksRealmId,
  getCurrentXeroOrganizationId,
  getCurrentZohoOrganizationId,
  getSelectedBusinessCategories,
  getSelectedBusinessClasses,
  getSelectedBusinessData,
  getSelectedBusinessItems,
  getSelectedBusinessProjects,
  getSelectedBusinessTaxRates
} from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Countries from "const/Countries";
import DataConstants from "const/DataConstants";
import Header from "./lib/Header";
import LineItem from "./lib/LineItem";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";
import useDocumentContext from "hooks/useDocumentContext";
import useShowCommonModal from "hooks/useShowCommonModal";

const { DOCUMENT_TYPES: { SALES_RECEIPT } } = DataConstants;

const stopPropagation = (event) => event.stopPropagation();

export const LineItems = memo(() => {
  const showModal = useShowCommonModal();

  const { uiTexts, messages } = useSelector(getTextsData);

  const businessUser = useSelector(checkIsBusinessUser);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const zohoBusiness = !!useSelector(getCurrentZohoOrganizationId);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const { countryCode } = useSelector(getActiveOrganization);

  const categories = useSelector(getSelectedBusinessCategories);

  const taxRates = useSelector(getSelectedBusinessTaxRates);

  const projects = useSelector(getSelectedBusinessProjects);

  const classes = useSelector(getSelectedBusinessClasses);

  const items = useSelector(getSelectedBusinessItems);

  const {
    documentFrozen,
    documentState: { lineItems, paymentType, type },
    setDocumentState
  } = useDocumentContext();

  const {
    settings: {
      allowClientCategorySelection,
      allowClientClassSelection,
      allowClientProjectSelection,
      allowClientTaxRateSelection
    } = {}
  } = useSelector(getSelectedBusinessData);

  const [selectedRowsIds, setSelectedRowsIds] = useState([]);

  const usCountry = countryCode === Countries.US;

  const enableCategories = (!businessUser || allowClientCategorySelection);

  const enableClasses = (!businessUser || allowClientClassSelection) && !!classes.length;

  const enableProjects = (!businessUser || allowClientProjectSelection)
    && paymentType === DataConstants.DOCUMENT_PAYMENT_TYPES.BUY && !!projects.length;

  const buyPaymentType = paymentType === DataConstants.DOCUMENT_PAYMENT_TYPES.BUY;

  const xeroFilteredItems = useMemo(() => {
    if (!xeroBusiness || businessUser) return [];

    return xeroBusiness && items.filter(({ salesItem, purchaseItem }) => {
      return !paymentType || (buyPaymentType ? purchaseItem : salesItem);
    });
  }, [xeroBusiness, businessUser, items, paymentType, buyPaymentType]);

  const enableItems = !businessUser && xeroBusiness && !!xeroFilteredItems.length;

  const lineItemsIds = useMemo(() => lineItems.map(({ uuid }) => uuid), [lineItems]);

  const allRowSelected = useMemo(() => lineItemsIds.every((uuid) => selectedRowsIds.includes(uuid)),
    [lineItemsIds, selectedRowsIds]);

  const mappedRowsIds = useMemo(() => {
    return lineItems.filter((lineItem) => lineItem.category?.code).map((item) => item.uuid);
  }, [lineItems]);

  const unmappedRowsIds = useMemo(() => lineItemsIds.filter((uuid) => !mappedRowsIds.includes(uuid)),
    [lineItemsIds, mappedRowsIds]);

  const allUnmappedChecked = useMemo(() => {
    return unmappedRowsIds.length && unmappedRowsIds.every((id) => selectedRowsIds.includes(id));
  }, [selectedRowsIds, unmappedRowsIds]);

  const allMappedChecked = useMemo(() => {
    return mappedRowsIds.length && mappedRowsIds.every((id) => selectedRowsIds.includes(id));
  }, [selectedRowsIds, mappedRowsIds]);

  const categoryOrItemsSelectOptions = useMemo(() => {
    if (!enableCategories) return [];

    return [type !== SALES_RECEIPT && uiTexts.category, !xeroBusiness && items.length && uiTexts.item].filter(Boolean);
  }, [items.length, type, xeroBusiness, uiTexts, enableCategories]);

  const handleSelectAllRowChange = useCallback(() => {
    setSelectedRowsIds(() => {
      return allRowSelected ? [] : lineItems.map(({ uuid }) => uuid);
    });
  }, [allRowSelected, lineItems]);

  const handleBulkLineItemsChange = useCallback((data) => {
    if (data) {
      const preselectTaxRateId = paymentType && data.item?.[buyPaymentType ? "purchaseTaxType" : "salesTaxType"];

      const preselectCategoryCode = paymentType && data.item?.[buyPaymentType ? "purchaseCategoryCode" : "salesCategoryCode"];

      const preselectTaxRate = !data.taxRate && (
        (data.category?.taxType && Utils.arrayFindById(taxRates, data.category.taxType))
        || (preselectTaxRateId && Utils.arrayFindById(taxRates, preselectTaxRateId))
      );

      const preselectClass = !data.class && data.item?.classId && Utils.arrayFindById(classes, data.item.classId);

      const preselectCategory = !data.category && preselectCategoryCode
        && Utils.arrayFind(categories, "code", preselectCategoryCode);

      setDocumentState({
        lineItems: lineItems.map((listItem) => {
          if (!selectedRowsIds.includes(listItem.uuid)) return listItem;

          const { item, category, ...rest } = data;

          return {
            ...listItem,
            ...rest,
            item: xeroBusiness ? (item || listItem.item) : (category ? undefined : item),
            category: xeroBusiness
              ? (category || (listItem.category?.code && listItem.category) || preselectCategory)
              : (item ? undefined : category),
            taxRate: preselectTaxRate && !listItem.taxRate?.id ? preselectTaxRate : listItem.taxRate,
            class: preselectClass && !listItem.class?.id ? preselectClass : listItem.class
          };
        })
      });
    }
    setSelectedRowsIds([]);
  }, [setDocumentState, lineItems, selectedRowsIds, xeroBusiness, taxRates, classes, categories, paymentType, buyPaymentType]);

  const handleBulkDelete = useCallback(async() => {
    const result = await showModal({ text: messages.lineItemsDeleteConfirm, confirm: true });

    if (result) {
      setDocumentState({
        lineItems: lineItems.filter((item) => !selectedRowsIds.includes(item.uuid))
      });
    }
  }, [lineItems, messages, selectedRowsIds, setDocumentState, showModal]);

  const handleSelectAllUnmappedClick = useCallback(() => {
    setSelectedRowsIds((prev) => {
      return allUnmappedChecked
        ? prev.filter((id) => !unmappedRowsIds.includes(id))
        : [...prev, ...(unmappedRowsIds.filter((id) => !selectedRowsIds.includes(id)))];
    });
  }, [allUnmappedChecked, selectedRowsIds, unmappedRowsIds]);

  const handleSelectAllMappedClick = useCallback(() => {
    setSelectedRowsIds((prev) => {
      return allMappedChecked
        ? prev.filter((id) => !mappedRowsIds.includes(id))
        : [...prev, ...(mappedRowsIds.filter((id) => !selectedRowsIds.includes(id)))];
    });
  }, [allMappedChecked, mappedRowsIds, selectedRowsIds]);

  const handleSelectRowChange = useCallback((uuid, selected) => {
    setSelectedRowsIds((prev) => {
      return selected ? prev.filter((item) => item !== uuid) : [...prev, uuid];
    });
  }, []);

  useEffect(() => {
    setSelectedRowsIds((prev) => prev.filter((uuid) => Utils.arrayFindById(lineItems, uuid)));
  }, [lineItems]);

  return (
    <div
      className={Css.lineItemsContainer}
      data-component="lineItemsContainer">
      <Header
        count={lineItems.length}
        paymentType={paymentType}
        enableCategories={enableCategories}
        enableItems={enableItems}
        enableClasses={enableClasses}
        enableProjects={enableProjects}
        selectedCount={selectedRowsIds.length}
        onChange={handleBulkLineItemsChange}
        onDelete={handleBulkDelete} />
      <div className={Css.container}>
        <div className={Css.table}>
          <div className={Css.head}>
            <div className={Css.row}>
              <div className={classNames(Css.cell, Css.cellNumber)}>
                <DropDown disabled={documentFrozen}>
                  <DropDownToggle className={Css.checkAll}>
                    <Checkbox
                      disabled={documentFrozen}
                      checked={allRowSelected}
                      onClick={stopPropagation}
                      onChange={handleSelectAllRowChange} />
                    <DropDownCaret />
                  </DropDownToggle>
                  <DropDownContent portal className={Css.checkAllDropDownContent}>
                    <DropDownMenuItem disabled={!unmappedRowsIds.length} onClick={handleSelectAllUnmappedClick}>
                      <Checkbox checked={allUnmappedChecked}>
                        <span>{`${uiTexts.selectUnmappedItems} (${unmappedRowsIds.length})`}</span>
                      </Checkbox>
                    </DropDownMenuItem>
                    <DropDownMenuItem disabled={!mappedRowsIds.length} onClick={handleSelectAllMappedClick}>
                      <Checkbox checked={allMappedChecked}>
                        <span>{`${uiTexts.selectMappedItems} (${mappedRowsIds.length})`}</span>
                      </Checkbox>
                    </DropDownMenuItem>
                    <DropDownMenuItem onClick={handleSelectAllRowChange}>
                      <Checkbox checked={allRowSelected}>
                        <span>{`${uiTexts.selectAllItems} (${lineItems.length})`}</span>
                      </Checkbox>
                    </DropDownMenuItem>
                  </DropDownContent>
                </DropDown>
              </div>
              <div className={classNames(Css.cell, Css.cellDescription)}>
                <div>{uiTexts.description}</div>
              </div>
              <div className={classNames(Css.cell, Css.cellQuantity)}>
                <div>{uiTexts.quantity}</div>
              </div>
              <div className={classNames(Css.cell, Css.cellAmount)}>
                <div>{uiTexts.subtotal}</div>
              </div>
              {xeroBusiness || zohoBusiness || (quickBooksBusiness && !usCountry)
                ? ((!businessUser || allowClientTaxRateSelection) && !!taxRates.length && (
                  <div className={classNames(Css.cell, Css.cellTaxRate)}>
                    <div>{uiTexts.taxRate}</div>
                  </div>
                ))
                : (
                  <div className={classNames(Css.cell, Css.cellVat)}>
                    <div>{`${uiTexts.vat} (%)`}</div>
                  </div>
                )}
              {!!xeroFilteredItems.length && (
                <div className={classNames(Css.cell, Css.cellItems)}>
                  <div>{uiTexts.item}</div>
                </div>
              )}
              {!!categoryOrItemsSelectOptions.length && (
                <div className={classNames(Css.cell, Css.cellCategory, categoryOrItemsSelectOptions.length > 1 && Css.extended)}>
                  <div>{categoryOrItemsSelectOptions.join(" / ")}</div>
                </div>
              )}
              {enableClasses && (
                <div className={classNames(Css.cell, Css.cellClass)}>
                  <div>{uiTexts.class}</div>
                </div>
              )}
              {enableProjects && (
                <div className={classNames(Css.cell, Css.cellProjects)}>
                  <div>{[
                    projects.some(({ customer }) => !customer) && uiTexts.project,
                    projects.some(({ customer }) => customer) && uiTexts.customer
                  ].filter(Boolean).join("/")}</div>
                </div>
              )}
              {lineItems.length > 0
                ? <div className={classNames(Css.cell, Css.removeCell)} />
                : null}
            </div>
          </div>
          {lineItems.map((lineItem, index) => {
            return (
              <LineItem
                key={lineItem.uuid}
                index={index}
                lineItem={lineItem}
                paymentType={paymentType}
                enableClasses={enableClasses}
                enableProjects={enableProjects}
                xeroFilteredItems={xeroFilteredItems}
                categoryOrItemsSelectOptionsLength={categoryOrItemsSelectOptions.length}
                selected={selectedRowsIds.includes(lineItem.uuid)}
                deleteEnable={lineItems.length > 1}
                onSelect={handleSelectRowChange} />
            );
          })}
        </div>
      </div>
    </div>
  );
});
