import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import BulkActions from "./lib/BulkActions";
import Button from "nlib/ui/Button";
import React, { useCallback, useMemo } from "react";
import Utils from "utils/Utils";
import useDocumentContext from "hooks/useDocumentContext";

const newLineItem = () => {
  return {
    uuid: uuid(),
    newLineItem: true,
    amountTotal: 0,
    amountBase: 0,
    amountVat: 0,
    vatRate: 0,
    description: ""
  };
};

const Header = (props) => {
  const {
    count,
    selectedCount,
    paymentType,
    enableCategories,
    enableItems,
    enableClasses,
    enableProjects,
    onDelete,
    onChange
  } = props;

  const { uiTexts, messages } = useSelector(getTextsData);

  const {
    lineItemsAmountGreaterThanDocument,
    lineItemsAmountLessThanDocument,
    lineItemsAmountVatGreaterThanDocument,
    lineItemsAmountVatLessThanDocument,
    documentFrozen,
    documentState: { lineItems },
    setDocumentState
  } = useDocumentContext();

  const handleAddRowClick = useCallback(() => {
    setDocumentState({ lineItems: [...lineItems, newLineItem()] });
  }, [lineItems, setDocumentState]);

  const lineItemsErrors = useMemo(() => {
    const [
      lineItemsErrorTextA,
      lineItemsErrorTextB,
      lineItemsErrorTextC,
      lineItemsErrorTextD
    ] = messages.lineItemsMismatch;

    return [
      lineItemsAmountGreaterThanDocument && lineItemsErrorTextA,
      lineItemsAmountLessThanDocument && lineItemsErrorTextB,
      lineItemsAmountVatGreaterThanDocument && lineItemsErrorTextC,
      lineItemsAmountVatLessThanDocument && lineItemsErrorTextD
    ].filter(Boolean);
  }, [
    lineItemsAmountGreaterThanDocument,
    lineItemsAmountLessThanDocument,
    lineItemsAmountVatGreaterThanDocument,
    lineItemsAmountVatLessThanDocument,
    messages.lineItemsMismatch
  ]);

  return (
    <div className={Css.tableHeader}>
      <div className={Css.title}>
        {`${uiTexts.lineItems} ${count ? `(${count})` : ""}`}
      </div>
      {!documentFrozen && (
        selectedCount
          ? (
            <BulkActions
              paymentType={paymentType}
              enableCategories={enableCategories}
              enableItems={enableItems}
              enableClasses={enableClasses}
              enableProjects={enableProjects}
              onChange={onChange}
              onDelete={onDelete} />
          )
          : (
            !!lineItemsErrors.length && (
              <div className={Css.errors}>
                <Icons.WarningCircle weight="bold" />
                <span>{Utils.capitalizeText(lineItemsErrors.join("; "))}</span>
              </div>
            )
          )
      )}
      <Button
        light primary
        disabled={documentFrozen}
        className={Css.addLineItemButton}
        icon={Icons.Plus}
        onClick={handleAddRowClick}>
        <span>{uiTexts.addNewLineItem}</span>
      </Button>
    </div>
  );
};

export default Header;
