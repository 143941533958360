import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Input } from "nlib/ui";
import BrowserEvents from "const/BrowserEvents";
import Constants from "const/Constants";
import React, { useCallback, useEffect } from "react";
import classNames from "classnames";
import useDocumentContext from "hooks/useDocumentContext";

const stopPropagation = (event) => {
  event.stopPropagation();
};

const TO_RESET = ["class", "location", "project", "taxRate", "item"];

const NO_VALUES = [undefined, null, ""];

const TableCell = (props) => {
  const {
    name,
    uuid,
    value,
    disabled,
    selectable = true,
    invalid: propsInvalid,
    displayValue,
    className,
    placeholder,
    inputComponent: InputComponent = Input,
    onChange,
    ...restProps
  } = props;

  const { documentFrozen, localState: { activeField }, setLocalState } = useDocumentContext();

  const invalid = !documentFrozen && propsInvalid;

  const fieldName = `${name}.${uuid}`;

  const active = activeField?.id === fieldName;

  const hasValueToDisplay = !NO_VALUES.includes(displayValue);

  const handleEditClick = useCallback((event) => {
    event.stopPropagation();
    setLocalState({ activeField: { id: fieldName, selectable } });
  }, [fieldName, selectable, setLocalState]);

  const handleSaveClick = useCallback((event) => {
    if (event) event.stopPropagation();
    if (TO_RESET.includes(name) && !value?.id) onChange(name);
    if (active) setLocalState({ activeField: null });
  }, [value, name, active, setLocalState, onChange]);

  const handleInputChange = useCallback((newValue) => {
    onChange(name, newValue);
  }, [name, onChange]);

  const handleClick = useCallback(() => {
    if (!documentFrozen) {
      setLocalState({ activeField: { id: fieldName, selectable } });
    }
  }, [documentFrozen, fieldName, selectable, setLocalState]);

  const handleWindowKeyUp = useCallback(({ key }) => {
    if (key === Constants.KEY_NAMES.ENTER) {
      handleSaveClick();
    }
  }, [handleSaveClick]);

  useEffect(() => {
    if (active) {
      window.addEventListener(BrowserEvents.KEY_UP, handleWindowKeyUp);

      return () => {
        window.removeEventListener(BrowserEvents.KEY_UP, handleWindowKeyUp);
      };
    }

    return () => {};
  }, [active, handleWindowKeyUp]);

  return (
    <div
      className={classNames(Css.tableCell, className, {
        [Css.editing]: active,
        [Css.invalid]: invalid,
        [Css.frozen]: documentFrozen
      })}
      onClick={handleClick}>
      <div className={Css.content}>
        {!documentFrozen && active
          ? (
            <>
              <InputComponent
                autoFocus
                {...restProps}
                invalid={invalid}
                placeholder={placeholder}
                value={value}
                className={Css.input}
                onChange={handleInputChange}
                onClick={stopPropagation} />
              <div className={Css.icon} onClick={handleSaveClick}>
                <Icons.PencilSimple weight="bold" className={CommonCss.highlightText} />
              </div>
            </>
          )
          : (
            <>
              <div
                className={classNames(Css.value, !hasValueToDisplay && Css.placeholder)}>
                {invalid && <Icons.WarningCircle className={Css.iconInvalid} />}
                <div title={hasValueToDisplay ? displayValue : undefined}>
                  {hasValueToDisplay ? displayValue : (placeholder || Constants.EMPTY_PLACEHOLDER)}
                </div>
              </div>
              {!documentFrozen && (
                <div className={Css.icon} onClick={handleEditClick}>
                  <Icons.PencilSimple weight="bold" />
                </div>
              )}
            </>
          )}
      </div>
    </div>
  );
};

export default React.memo(TableCell);
