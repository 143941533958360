import { ColorSet } from "nlib/common/EditDocumentContent/constants";
import Constants from "const/Constants";
import Countries from "const/Countries";
import DataConstants from "const/DataConstants";
import Documents from "utils/Documents";
import Utils from "utils/Utils";

const TOP_COORD_MULTIPLIER = 0.99982;

const PAGES_BOTTOM_MARGIN = 8;

const searchContact = (contactsData, { id, idNumber, vatId, name }, { paymentType, integrationService, xeroBusiness }) => {
  const {
    DOCUMENT_PAYMENT_TYPES: { BUY },
    CONTACT_SUB_TYPES: { VENDOR, CUSTOMER }
  } = DataConstants;

  const buyPaymentType = paymentType === BUY;

  const contactData = contactsData.find((contact) => {
    if (paymentType && integrationService && !xeroBusiness) {
      if ((buyPaymentType && contact.subType !== VENDOR) || (!buyPaymentType && contact.subType !== CUSTOMER)) {
        return false;
      }
    }
    if (id && id === contact.id) return true;

    return Utils.checkSimilarityBy({ idNumber, vatId, name }, contact);
  });

  return contactData;
};

export const getColoredBlocks = (blockNames, fieldColors, ocrMeta) => {
  return blockNames.map((name) => {
    return ocrMeta[name]?.boundingBox ? {
      color: fieldColors[name],
      block: {
        id: name,
        boundingBox: ocrMeta[name]?.boundingBox
      },
      sourceFieldId: name
    } : null;
  }).filter(Boolean);
};

export const getColoredBlocksToDisplay = (coloredBlocks, activeField, hoveredField) => {
  return coloredBlocks.filter((block) => {
    return (!activeField && !hoveredField) || block.sourceFieldId === activeField?.id || block.sourceFieldId === hoveredField?.id;
  });
};

export const getColorFromFieldId = (fieldId) => {
  const numb = [...fieldId, "2"].reduce((aggregator, char) => {
    return aggregator + char.charCodeAt(0);
  }, 0);

  return ColorSet[numb % ColorSet.length];
};

export const toMinMax = (rect) => {
  const { minX, maxX } = rect.start.x <= rect.end.x
    ? { minX: rect.start.x, maxX: rect.end.x }
    : { minX: rect.end.x, maxX: rect.start.x };

  const { minY, maxY } = rect.start.y <= rect.end.y
    ? { minY: rect.start.y, maxY: rect.end.y }
    : { minY: rect.end.y, maxY: rect.start.y };

  return { minX, maxX, minY, maxY };
};

export const getBlockRenderCoords = (boundingBox, docPageHeights, docRenderWidth) => {
  if (!boundingBox) return null;

  const pageHeight = docPageHeights[boundingBox.page] || 0;

  let pageOffset = 0;

  // eslint-disable-next-line no-loops/no-loops
  for (let index = 0; index < boundingBox.page; ++index) {
    pageOffset += (docPageHeights[index] || 0) + PAGES_BOTTOM_MARGIN;
  }

  const left = boundingBox.left * docRenderWidth;

  const top = pageOffset + (boundingBox.top * pageHeight);

  const height = boundingBox.height * pageHeight;

  const width = boundingBox.width * docRenderWidth;

  return { left, top: top * TOP_COORD_MULTIPLIER, height, width };
};

export const recalculateLineItemFields = (prev, fieldName, value, extraData = {}) => {
  const { xeroBusiness, classes = [], taxRates = [] } = extraData;

  switch (fieldName) {
    case "amountTotal": {
      const { vatRate } = prev;

      const amountBase = vatRate
        ? Utils.toMoneyNumber((value || 0) / (1 + ((vatRate || 0) / Constants.PERCENTS_MULTIPLIER))) : value;

      const amountVat = vatRate ? Utils.toMoneyNumber((value || 0) - amountBase) : 0;

      return { ...prev, [fieldName]: value, amountVat, amountBase };
    }
    case "amountBase": {
      const { vatRate } = prev;

      const amountVat = value && vatRate ? Utils.toMoneyNumber(value * vatRate / Constants.PERCENTS_MULTIPLIER) : 0;

      const amountTotal = Utils.toMoneyNumber((value || 0) + (amountVat || 0));

      return { ...prev, [fieldName]: value, amountVat, amountTotal };
    }
    case "amountVat": {
      const { amountBase } = prev;

      const vatRate = value && amountBase ? Utils.toMoneyNumber(value / amountBase * Constants.PERCENTS_MULTIPLIER) : 0;

      const amountTotal = Utils.toMoneyNumber((amountBase || 0) + (value || 0));

      return { ...prev, [fieldName]: value, amountTotal, vatRate, amountBase };
    }
    case "vatRate": {
      const { amountBase } = prev;

      const amountVat = value && amountBase
        ? Utils.toMoneyNumber(amountBase * value / Constants.PERCENTS_MULTIPLIER)
        : 0;

      const amountTotal = Utils.toMoneyNumber((amountBase || 0) + (amountVat || 0));

      return { ...prev, [fieldName]: value, amountVat, amountBase, amountTotal };
    }
    case "taxRate": {
      const { amountBase } = prev;

      if (value?.id) {
        const amountVat = amountBase
          ? Utils.toMoneyNumber(amountBase * value.rate / Constants.PERCENTS_MULTIPLIER)
          : 0;

        const amountTotal = Utils.toMoneyNumber((amountBase || 0) + (amountVat || 0));

        return { ...prev, [fieldName]: value, vatRate: value.rate, amountVat, amountTotal };
      }

      return { ...prev, [fieldName]: value, vatRate: 0, amountVat: 0, amountTotal: amountBase };
    }
    case "category":
      return {
        ...prev,
        [fieldName]: value,
        item: xeroBusiness ? prev.item : undefined,
        taxRate: (value?.taxType && Utils.arrayFind(taxRates, "id", value.taxType)) || prev.taxRate
      };
    case "item":
      return {
        ...prev,
        [fieldName]: value,
        category: xeroBusiness ? prev.category : undefined,
        class: (value?.classId && Utils.arrayFind(classes, "id", value.classId)) || prev.class
      };
    default:
      return { ...prev, [fieldName]: value };
  }
};

export const updateState = (update, state, extraData) => {
  const {
    DOCUMENT_TYPES: { RECEIPT },
    DOCUMENT_PAYMENT_TYPES: { BUY },
    ADVANCED_TRANSACTION_TYPES: { EXPENSE },
    CONTACT_SUB_TYPES: { VENDOR, CUSTOMER }
  } = DataConstants;

  const {
    recogniseData,
    countryCode,
    quickBooksBusiness,
    xeroBusiness,
    integrationService,
    contactsData,
    categories,
    status
  } = extraData;

  const keysToUpdate = Object.keys(update);

  const { lineItems, amountVat, amountBase, paymentType, paymentDate, dueDate, issueDate, paid } = state;

  const usCountry = countryCode === Countries.US;

  keysToUpdate.forEach((key) => {
    switch (key) {
      case "amountBase": {
        if (lineItems.length === 1) {
          const lineItem = recalculateLineItemFields(
            lineItems[0],
            "amountBase",
            Utils.toMoneyNumber(update.amountBase || 0, true, "...."),
            { xeroBusiness }
          );

          update.lineItems = [lineItem];
          update.amountVat = lineItem.amountVat;
          update.amount = lineItem.amountTotal;
        } else {
          update.amount = Utils.toMoneyNumber((update.amountBase || 0) + (amountVat || 0));
        }
        break;
      }
      case "amountVat": {
        update.amount = Utils.toMoneyNumber((update.amountVat || 0) + (amountBase || 0));
        if (lineItems.length === 1) {
          const lineItem = recalculateLineItemFields(
            lineItems[0],
            "amountVat",
            Utils.toMoneyNumber(update.amountVat || 0),
            { xeroBusiness }
          );

          update.lineItems = [lineItem];
          update.amountBase = lineItem.amountBase;
          update.amountTotal = lineItem.amountTotal;
        }
        break;
      }
      case "amount": {
        if (lineItems.length === 1) {
          const lineItem = recalculateLineItemFields(
            lineItems[0],
            "amountTotal",
            Utils.toMoneyNumber(update.amount || 0, true, "...."),
            { xeroBusiness }
          );

          update.lineItems = [lineItem];
          update.amountVat = lineItem.amountVat;
          update.amountBase = Utils.toMoneyNumber(Math.max((update.amount || 0) - (update.amountVat || 0), 0));
        } else {
          update.amountBase = Utils.toMoneyNumber(Math.max((update.amount || 0) - (state.amountVat || 0), 0));
        }
        break;
      }
      case "issueDate": {
        if (paid) update.paymentDate = update.issueDate;
        break;
      }
      case "payTo": {
        if (update.payTo?.serviceAccountId) {
          update.paid = true;
          if (!paymentDate && (dueDate || issueDate)) {
            update.paymentDate = Utils.formatApiDate(dueDate || issueDate);
          }
        }
        break;
      }
      case "exportAs": {
        update.paid = update.exportAs === EXPENSE;
        update.payTo = {};
        break;
      }
      case "detailedTaxRates": {
        update.amountVat = update.detailedTaxRates
          .reduce((aggregator, currentValue) => aggregator + +(currentValue.value || 0), 0);
        update.amount = Utils.toMoneyNumber((update.amountVat || 0) + (amountBase || 0));
        break;
      }
      case "address": {
        update.vendorId = update.address.id;
        break;
      }
      case "paymentType": {
        const buyPaymentType = update.paymentType === BUY;

        const { payTo: { serviceAccountId, ...restPayTo } } = state;

        const { addresses: { recipient = {}, sender = {} } = {} } = recogniseData;

        const nextAddress = buyPaymentType ? sender : recipient;

        const contactSubType = buyPaymentType ? VENDOR : CUSTOMER;

        update.exportAs = quickBooksBusiness && update.type === RECEIPT ? EXPENSE : "";
        update.paid = !!(quickBooksBusiness && update.type === RECEIPT);
        update.payTo = restPayTo;
        update.linkedDocumentsIds = [];
        if (status === DataConstants.STATUSES.TO_REVIEW) {
          const contactData = searchContact(contactsData, nextAddress, {
            paymentType: update.paymentType,
            integrationService,
            xeroBusiness
          });

          update.address = contactData || { ...nextAddress, subType: contactSubType };
          update.vendorId = contactData ? contactData.id : undefined;
        }
        if (quickBooksBusiness && !usCountry) {
          const detailedTaxRates = Documents.getDetailedTaxRates(update.paymentType, lineItems);

          update.detailedTaxRates = detailedTaxRates;
          update.amountVat = detailedTaxRates.reduce((aggregator, currentValue) => aggregator + currentValue.value || 0, 0);
        }
        if (xeroBusiness) {
          update.lineItems = lineItems.map((lineItem) => {
            const { purchaseItem, salesItem, purchaseCategoryCode, salesCategoryCode } = lineItem.item || {};

            if (buyPaymentType ? !purchaseItem : !salesItem) {
              return { ...lineItem, item: undefined };
            }

            const foundCategory = paymentType && Utils.arrayFind(
              categories,
              "code",
              buyPaymentType ? purchaseCategoryCode : salesCategoryCode
            );

            return foundCategory ? { ...lineItem, category: foundCategory } : lineItem;
          });
        }
        break;
      }
      case "lineItems": {
        const prevLineItemsAmounts = Documents.calculateLineItemsData({
          lineItems, paymentType, countryCode, quickBooksBusiness });

        const lineItemsAmounts = Documents.calculateLineItemsData({
          lineItems: update.lineItems, paymentType, countryCode, quickBooksBusiness });

        if (prevLineItemsAmounts.amount !== lineItemsAmounts.amount
            || prevLineItemsAmounts.taxesHash !== lineItemsAmounts.taxesHash) {
          update.amountBase = lineItemsAmounts.amountBase;
          update.amountVat = lineItemsAmounts.amountVat;
          update.amount = lineItemsAmounts.amount;
          update.tags = [
            ...new Set(
              lineItems.map(({ category, item }) => {
                category = category || {};
                item = item || {};

                return category.code || item.id ? category.name || item.name : null;
              }).filter(Boolean)
            )
          ];
        }
        if (lineItemsAmounts.detailedTaxRates) {
          update.detailedTaxRates = lineItemsAmounts.detailedTaxRates;
        }
        break;
      }
      default:
        break;
    }
  });

  return update;
};

export const rotateBoundingBox = ({ boundingBox, angle, width: origWidth, height: origHeight }) => {
  const rightRotated = 90;

  const leftRotated = 270;

  const [rotatedWidth, rotatedHeight] = [rightRotated, leftRotated].includes(angle)
    ? [origHeight, origWidth] : [origWidth, origHeight];

  const half = 2;

  const straightAngle = 180;

  const xCoord = boundingBox.left * origWidth;

  const yCoord = boundingBox.top * origHeight;

  const width = boundingBox.width * origWidth;

  const height = boundingBox.height * origHeight;

  const points = [
    [xCoord, yCoord],
    [xCoord + width, yCoord],
    [xCoord, yCoord + height],
    [xCoord + width, yCoord + height]
  ];

  const centerX = origWidth / half;

  const centerY = origHeight / half;

  const angleRad = (angle * Math.PI) / straightAngle;

  const rotatedPoints = points.map(([px, py]) => {
    const xNew = px - centerX;

    const yNew = py - centerY;

    const newX = (xNew * Math.cos(angleRad)) - (yNew * Math.sin(angleRad)) + centerX;

    const newY = (xNew * Math.sin(angleRad)) + (yNew * Math.cos(angleRad)) + centerY;

    return [newX, newY];
  });

  const minX = Math.min(...rotatedPoints.map(([point]) => point));

  const minY = Math.min(...rotatedPoints.map(([, point]) => point));

  const maxX = Math.max(...rotatedPoints.map(([point]) => point));

  const maxY = Math.max(...rotatedPoints.map(([, point]) => point));

  const newBoundingBox = {
    left: (minX - ((origWidth - rotatedWidth) / half)) / rotatedWidth,
    top: (minY - ((origHeight - rotatedHeight) / half)) / rotatedHeight,
    width: (maxX - minX) / rotatedWidth,
    height: (maxY - minY) / rotatedHeight,
    page: boundingBox.page
  };

  return newBoundingBox;
};
