import Css from "./style.module.scss";

import { ColorSet } from "./constants";
import Content from "./Content";
import Provider from "./Provider";
import React, { useMemo, useRef } from "react";

const COLORED_BLOCKS = [
  "documentType",
  "originalDocumentId",
  "currency",
  "amountBase",
  "amountVat",
  "amount",
  "issueDate",
  "dueDate",
  "address"
];

const DocumentLabeler = ({ disabled, ...restProps }) => {
  const rootRef = useRef(null);

  return (
    <div ref={rootRef} className={Css.documentLabeler}>
      <Provider {...restProps} rootRef={rootRef}>
        <Content disabled={disabled} />
      </Provider>
    </div>
  );
};

const EditDocumentContent = (props) => {
  const { documentData } = props;

  const fieldColors = useMemo(() => {
    return COLORED_BLOCKS
      .reduce((result, field, index) => ({ ...result, [field]: ColorSet[index % ColorSet.length] }), {});
  }, []);

  return (
    <div className={Css.container}>
      <DocumentLabeler
        attachment={documentData?.attachment}
        fieldColors={fieldColors}
        blockNames={COLORED_BLOCKS}
        {...props} />
    </div>
  );
};

export default React.memo(EditDocumentContent);
